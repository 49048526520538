var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('validation-observer',{ref:"simpleRules"},[_c('b-card-code',{attrs:{"title":"Form Surat Keluar"}},[_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Perihal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Perihal","label-for":"Perihal"}},[_c('b-form-input',{attrs:{"id":"Perihal","placeholder":"Tulis Perihal"},model:{value:(_vm.Perihal),callback:function ($$v) {_vm.Perihal=$$v},expression:"Perihal"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Tanggal","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tanggal","label-for":"Tanggal"}},[_c('b-form-datepicker',{attrs:{"id":"date-datepicker","locale":"id"},model:{value:(_vm.startDate),callback:function ($$v) {_vm.startDate=$$v},expression:"startDate"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])],1)]}}])})],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Kepada","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Kepada","label-for":"Kepada"}},[_c('b-form-input',{attrs:{"id":"Kepada","placeholder":"Tulis Kepada"},model:{value:(_vm.to),callback:function ($$v) {_vm.to=$$v},expression:"to"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Alamat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Alamat","label-for":"Alamat"}},[_c('b-form-input',{attrs:{"id":"Alamat","placeholder":"Tulis Alamat"},model:{value:(_vm.address),callback:function ($$v) {_vm.address=$$v},expression:"address"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"Instansi","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Instansi","label-for":"Instansi"}},[_c('b-form-input',{attrs:{"id":"Instansi","placeholder":"Tulis Instansi"},model:{value:(_vm.agency),callback:function ($$v) {_vm.agency=$$v},expression:"agency"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('b-form-group',{attrs:{"label":"Kategori","label-for":"Kategori"}},[_c('v-select',{attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","placeholder":"Pilih Kategori","options":_vm.optionsKategori},model:{value:(_vm.selectedKategori),callback:function ($$v) {_vm.selectedKategori=$$v},expression:"selectedKategori"}})],1)],1)],1),_c('b-row',{staticClass:"match-height"},[_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"File","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"File Surat","label-for":"FileSurat"}},[_c('b-form-file',{ref:"file",attrs:{"id":"FileSurat","type":"file","placeholder":"Tambah File Surat","drop-placeholder":"Drop file here...","multiple":"multiple"},on:{"change":_vm.fileChange},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-col',{attrs:{"md":"6"}},[_c('validation-provider',{attrs:{"name":"FileSurat","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-group',{attrs:{"label":"Tag","label-for":"tag"}},[_c('b-form-tags',{attrs:{"input-id":"tags-basic","placeholder":"Tambah Tag"},model:{value:(_vm.tags),callback:function ($$v) {_vm.tags=$$v},expression:"tags"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1),_c('b-button',{staticClass:"bg-gradient-primary mt-2",attrs:{"variant":"outline-primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm($event)}}},[_c('feather-icon',{staticClass:"mr-50",attrs:{"icon":"SaveIcon"}}),_c('span',{staticClass:"align-middle"},[_vm._v("Simpan")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }